import {Component} from "react";
import React from "react";

import Content from "../../organisms/Content";
import Carousel from '../../organisms/Carousel';
import '../../../images/icons.css';
import ProjectHero from "../../organisms/ProjectHero";
import TextBlock from "../../molecules/TextBlock";
import ProjectDescription from "../../organisms/ProjectDescription";
import DoubleTextBlock from "../../molecules/DoubleTextBlock";
import ProjectInformation from "../../molecules/ProjectInformation";
import ColoredBackground from "../../atoms/ColoredBackground";
import VideoBox from "../../molecules/VideoBox";
import SeparatorImage from "../../molecules/SeparatorImage";
import VisibilityFader from "../../atoms/VisibilityFader";
import {connect} from "react-redux";
import styled from "styled-components";
import {media} from "styled-bootstrap-grid";


const LinkUnderlined = styled.span`
  position: relative;
  cursor: pointer;
  vertical-align: unset !important;

&:before {
  background-color: #fff;
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 28px;
  bottom: 0;
  left: 0;
  /*background-image: linear-gradient(90deg, #d23c69, #ffc850);*/
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
  ${media.lg`
  margin-top: 0;
  
  &:before {
  top: 32px;
}
  `}
`;

class MLSProject extends Component {

    render() {
        let slide_0 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_01.jpg',
            isVideo: false,
        };

        let slide_1 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_02.jpg',
            isVideo: false,
        };

        let slide_2 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_03.jpg',
            isVideo: false,
        };

        let slide_3 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_04.jpg',
            isVideo: false,
        };

        let slide_4 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_05.jpg',
            isVideo: false,
        };

        let slide_5 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_06.jpg',
            isVideo: false,
        };

        let slide_6 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_07.jpg',
            isVideo: false,
        };

        let slide_7 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_08.jpg',
            isVideo: false,
        };

        let slide_8 = {
            width: "1200",
            height: "640",
            url: '/images/mls/MLS_screenshot_09.jpg',
            isVideo: false,
        };


        // as well as the total energy output.

        return (
            <div>
                <ProjectHero imgUrl={'/images/mls/MLS_hero.jpg'}
                             imgUrlMobile={'/images/mls/MLS_hero_mobile.jpg'}
                             noWave={true}
                             title={"Machine Learning Sandbox"}
                             subTitle={"Interactive Evolution Game"}
                             subTitleMargin={'20px'}
                             isLight
                             overlayRGBA={"rgba(23,23,23,0.5)"}
                />

                <ProjectDescription shadow>
                    The Machine Learning Sandbox is an application in which players can stick together their own neural-network
                    controlled creatures using joints and bones to observe and influence
                    their evolutionary training process towards completing the levels.
                </ProjectDescription>

                <Content padded>

                    <VisibilityFader>
                        {this.props.device.isMobile ?
                            <ProjectInformation
                                blocks={[
                                    {
                                        caption: "context",
                                        text:
                                            <span>
                                            7th semester project<br/>
                                            Bachelor Thesis<br/>
                                            Game Design (BA)<br/>
                                            HTW Berlin
                                        </span>
                                    },
                                    {
                                        caption: "time",
                                        text:
                                            <span>
                                            2020-2021<br/>
                                            6 months
                                        </span>
                                    },
                                    {
                                        caption: "tasks",
                                        text:
                                            <span>
                                            Development<br/>
                                            Design<br/>
                                            Research<br/>
                                        </span>
                                    }
                                ]}
                            />
                            :
                            <ProjectInformation
                                blocks={[
                                    {
                                        caption: "",
                                        text: <span></span>
                                    },
                                    {
                                        caption: "context",
                                        text:
                                            <span>
                                            7th semester project<br/>
                                            Bachelor Thesis<br/>
                                            Game Design (BA)<br/>
                                            HTW Berlin
                                        </span>
                                    },
                                    {
                                        caption: "time",
                                        text:
                                            <span>
                                            2020-2021<br/>
                                            6 months
                                        </span>
                                    },
                                    {
                                        caption: "tasks",
                                        text:
                                            <span>
                                            Development<br/>
                                            Design<br/>
                                            Research<br/>
                                        </span>
                                    },
                                    {
                                        caption: "",
                                        text: <span></span>
                                    },
                                ]}
                            />
                        }

                    </VisibilityFader>


                    <VisibilityFader>


                        <VideoBox url={'https://www.dl.dropboxusercontent.com/s/91sqcq8f6lo9mxb/machine-leanring-sandbox_showreel_1920_60fps.mp4'}
                                  playPauseBackground
                        />

                    </VisibilityFader>

                    <VisibilityFader>
                        <TextBlock
                            blockText={true}
                            hasTopPadding={false}
                            caption={<span>gameplay</span>}
                            captionMobile={<span>gameplay</span>}>
                            After opening the application the player is able to select the first level - the top of a high tower in the midst of dense clouds. Using the ingame creature editor, players can stick together their first creature using bones and joints. When the player has selected a creation and clicks on the <i>Begin Evolution</i> button, a cardboard box appears and drops the first generation of creatures into the level. Each creature is controlled by its own - slightly different - neural network, rotating its joints, through which locomotion emerges. Over generations, creatures can be trained to behave in certain ways by feeding them <i>fitness spheres</i>, rewarding their behaviour. The level is completed as soon as a creature reaches the goal flag, unlocking the next level.
                        </TextBlock>
                    </VisibilityFader>

                    <VisibilityFader>
                        <Carousel slides={[slide_8, slide_0, slide_6, slide_1, slide_7, slide_2, slide_3,slide_5]} />
                    </VisibilityFader>

                    <ColoredBackground color={"#202020"}>
                        <VisibilityFader>
                            <TextBlock
                                blockText={true}
                                hasTopPadding={false}
                                caption={<span>motivation</span>}
                                captionMobile={<span>motivation</span>}
                                sideCaption={false}
                            >
                                I have always been fascinated by the clever solutions neural networks are able to come up with given various kinds of problems. The mysteries of these black boxes sparked the desire in me to learn about the ins and outs of machine learning by training a neural net myself.
                                For this reason I chose to create an application leveraging machine learning in the context of a playful game environment, combining my two loves for game design and algorithms.
                            </TextBlock>
                        </VisibilityFader>

                        <SeparatorImage imgUrl={'/images/mls/MLS_creature_seperator.jpg'} height={540} width={1920}/>


                        <VisibilityFader>
                            <TextBlock
                                blockText={true}
                                hasTopPadding={true}
                                caption={<span>workflow</span>}
                                captionMobile={<span>workflow</span>}
                                sideCaption={false}
                            >
                                During the first month of working on this project I did nothing but read papers and articles about machine learning,
                                trying to get a feel for the current state of the art. Quickly I learned that the field
                                of machine learning is quite extensive and the approaches as to how neural networks are
                                trained differ a lot. After ruling out deep learning algorithms due to performance and
                                viability concerns, I settled on the evolutionary <i>NEAT</i> algorithm (<i>NeuroEvolution of Augmenting Topologies</i>).
                                Since previous implementations of the algorithm were not suitable for my needs,
                                I refactored an existing implementation and used the resulting&nbsp;
                                <LinkUnderlined className={"hvr-wobble-top"} onClick={() => {window.location = "https://github.com/flo-wolf/UnitySharpNEAT/"}}>UnitySharpNEAT</LinkUnderlined> project as
                                the foundation for this project.<br/><br/>

                                After successfully getting the joints of a blocky prototype creature to rotate by the means of a <i>NEAT</i>-ly trained neural net, I began writing on a serialization system for creatures. By being able to save and load creatures an ingame creature editor became possible, allowing players to let all sorts of different behaviour emerge just based on different morphologies. During this whole process I was iterating over the UI design, trying to create a clean and straightforward user experience. Tester feedback revealed that players also wanted some sort of clear goal as well as more variety in the environment, which is why I added a few short levels to give them a well communicated objective and a physical playground.

                            </TextBlock>
                        </VisibilityFader>

                        <VisibilityFader>
                            <Carousel
                                slides={[
                                    slide_4,
                                    {
                                        width:"1200",
                                        height:"640",
                                        url:'/images/mls/MLS_process_02.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1200",
                                        height:"640",
                                        url:'/images/mls/MLS_process_03.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1200",
                                        height:"640",
                                        url:'/images/mls/MLS_process_04.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1200",
                                        height:"640",
                                        url:'/images/mls/MLS_process_05.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1200",
                                        height:"640",
                                        url:'/images/mls/MLS_process_06.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1200",
                                        height:"640",
                                        url:'/images/mls/MLS_process_01.jpg',
                                        isVideo: false,
                                    }
                                ]}
                            />
                        </VisibilityFader>
                    </ColoredBackground>


                    <ColoredBackground color={"#fcfcfc"}>
                        <VisibilityFader>
                            <DoubleTextBlock
                                blockText={true}
                                darkText={true}
                                captionLeft={<span>algorithm choice</span>}
                                textLeft={
                                    <span>
                                        The main challenge of this project has been selecting and successfully making use
                                        of the <i>NEAT</i> algorithm, which turned out to be a good fit for this undertaking.
                                        For instance, as soon as a creature starts to jump around and gets rewarded with
                                        fitness for it, the next generation will have adapted that jumpy behaviour.
                                        This has been a big concern for me in the beginning of the project, since I had
                                        no idea whether the manual training via distributing fitness would actually feel
                                        impactful, but luckily it all worked out. Sometime in the future I will swap the&nbsp;
                                        <i>NEAT</i> algorithm for <i>HyperNEAT</i> (<i>Hypercube-based NEAT</i>), which might produce even more coherent and stunning
                                        creature locomotion - though I am happy to not have gone down that road for the
                                        time being, since implementing <i>HyperNEAT</i> would have been a massive undertaking,
                                        which would have impacted the quality of other features.
                                    </span>
                                }
                                captionRight={<span>reflection</span>}
                                textRight={
                                    <span>
                                        In retrospect I am quite happy with the final result, which didn’t seem to be too harshly impacted by the midst of the pandemic, although the social aspect of being with other students and having active conversations about one another's projects is something I did miss a lot. Over the course of my bachelor semester I was able to approach machine learning hands on and satisfy my desire to experiment with neural networks. Although I only scratched the surface of what machine learning has to offer, I was able to develop a good understanding of the isolated research area surrounding evolutionary computation. Overall I deem this project a success and plan to keep working on it.

                                    </span>
                                }
                            />
                        </VisibilityFader>

                    </ColoredBackground>


                </Content>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    device: state.device
});


export default MLSProject = connect(mapStateToProps)(MLSProject);