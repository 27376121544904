import React, {Component} from 'react';
import styled from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import PropTypes from "prop-types";
import Content from "../organisms/Content";

const Wrap = styled.div`
    padding-top: ${(props) => props.noPadding ? "0px" : "var(--pad)"};
    width:100vw;
    height: 100%;
    background-color: ${(props) => props.color};
`;


class ColoredBackground extends Component {
    render(){
        return (
            <Wrap color={this.props.color} noPadding={this.props.noPadding}>
                {this.props.children}
            </Wrap>
        );
    }
}

ColoredBackground.propTypes = {
    color: PropTypes.string.isRequired,
    noPadding: PropTypes.string,
};

export default ColoredBackground;


/*
                <h5 style={{ color: "white", paddingBottom: "0"}}>
                    {this.props.title}
                </h5>
                <h5>
                    {this.props.children}
                </h5>
 */