import {Component} from "react";
import React from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

class ProgressiveImg extends Component {
    state = {
        currentImage: this.props.preview,
        loading: false,
        highResLoaded: false,
    };

    componentDidMount() {
       if(this.props.noVisibilityLoad)
           this.fetchImage(this.props.image)
    }

    componentWillUnmount() {
        if (this.loadingImage) {
            this.loadingImage.onload = null
        }
    }



    fetchImage(src) {

        if(!this.state.highResLoaded && !this.state.loading && this.state.currentImage !== src){
            console.log("fetchimage ", src);
            this.setState({loading: true}, () => {
                const image = new Image();
                image.onload = () => this.setState({ currentImage: this.loadingImage.src, loading: false, highResLoaded: true });
                image.src = src;
                this.loadingImage = image
            });

        }
    };

    //height: this.props.fixedHeight && this.props.fixedWidth ? (this.props.fixedHeight/this.props.fixedWidth) * 100 + '%' : '100%',
    //

    style = highResLoaded => {
        return {

            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            transform: `${!highResLoaded ? 'scale(1.3)' : 'scale(1)'}`,
            width: '100%',
            height: "100%",
             overflow: 'hidden',
            transition: 'filter 0.5s linear, transform 0.5s linear',
            filter: `${!highResLoaded ? 'blur(50px)' : ''}`,
            clip: 'rect(0px,0px,0px,0px)',
            clipPath: 'fill-box',
            //filter: 'blur(50px)'
        }
    };

    render() {

        const onChange = (isVisible) => {
            if(isVisible)
                    this.fetchImage(this.props.image);
        };


        const { currentImage, highResLoaded } = this.state;
        const { alt, overlayRGBA} = this.props;
        return <div style={{...this.props.style, overflow: 'hidden', maxHeight: this.props.maxHeight + "px", height: "100%" }}>

                    {overlayRGBA ?
                        <div style={{width: '100%', height: '100%', position: "absolute", backgroundColor: overlayRGBA, zIndex: '1'}}/>
                        :
                        null
                    }

                    <VisibilitySensor onChange={onChange} style={{width: '100%', height: '100%'}}
                                      partialVisibility
                                      offset={{right:-500, left: -500}}>
                        <img style={ this.style(highResLoaded)} src={currentImage} alt={alt}/>
                    </VisibilitySensor>

                </div>


    }
}

ProgressiveImg.propTypes = {
    image: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
    overlayRGBA: PropTypes.string,
    noVisibilityLoad: PropTypes.bool,
    maxHeight: PropTypes.string,
};

export default ProgressiveImg;