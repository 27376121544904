import {Component} from "react";
import React from "react";
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid';
import PropTypes from "prop-types";
import { media } from 'styled-bootstrap-grid';
import {connect} from "react-redux";


const Caption = styled.h3`
  text-transform: lowercase;
  text-align: center;
`;

const TextS = styled.h5`
  justify-content: center;
  text-align: center;
`;

const ColBlock = styled(Col)`
  padding-bottom: var(--pad);

`;

class ProjectInformation extends Component {
    render(){
        return (
            <Container>
                <Row justifyContent={"center"}>
                    {this.props.device.isMobile ?
                        this.props.blocks.map((block, index) => {

                            return(
                                <ColBlock col={6} lg={2} noGutter key={index}>
                                    <Caption>
                                        {block.caption}
                                    </Caption>

                                    <TextS>
                                        {block.text}
                                    </TextS>
                                </ColBlock>
                            );

                        })
                    :
                        this.props.blocks.map((block, index) => {

                            return(
                                <ColBlock style={{width: 'calc(100% / ' + this.props.blocks.length + ')'}} noGutter key={index}>
                                    <Caption>
                                        {block.caption}
                                    </Caption>

                                    <TextS>
                                        {block.text}
                                    </TextS>
                                </ColBlock>
                            );

                        })
                    }
                </Row>
            </Container>
        );
    }
}


ProjectInformation.propTypes = {
    blocks: PropTypes.arrayOf(PropTypes.shape({
            caption: PropTypes.string.isRequired,
            text: PropTypes.object.isRequired,
        })).isRequired,

};

const mapStateToProps = (state) => ({
    device: state.device
});


export default ProjectInformation = connect(mapStateToProps)(ProjectInformation);