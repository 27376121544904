import { TimelineMax as Timeline, Power1, CSSRulePlugin } from 'gsap/all';

// switch first

export const play = (pathname, node, appears, enterStartCallback, enterEndCallback) => {

    console.log("Play Page FadeIn to path: ", pathname);
    const delay = appears ? 0 : 0;
    let timelines = [];

    if (pathname === '/' || pathname === '/about')
        timelines[0] = enter(node, delay, true, enterStartCallback, enterEndCallback);
    else
        timelines[0]  = enter(node, delay, false, enterStartCallback, enterEndCallback);

    window
        .loadPromise
        .then(() => requestAnimationFrame(() => timelines.map(timeline => timeline.play())));
};

export const exit = (node, appears, isMobile) => {
    console.log("Play Page FadeOut : ", isMobile);
    const timeline = new Timeline({ paused: true });

    const transitionBox = node.querySelectorAll('.transition-box');
    const pageContent = node.querySelectorAll('.page__content');
    const projectDesc = node.querySelectorAll('.project__description');
    const heroTitle = node.querySelectorAll('.hero-title');
    const footerWrap = node.querySelectorAll('.footer__wrap');
    const navWrap = node.querySelectorAll('.nav__wrap');

    //transitionCallback();

    timeline
        .set(transitionBox, { y: "150vh", opacity: 1,  ease: Power1.easeIn })
        .to(pageContent, 0.3, { opacity: 0, ease: Power1.easeIn }, 0)
        .to(projectDesc, 0.3, { opacity: 0, ease: Power1.easeIn }, 0)
        .to(heroTitle, 0.3, { opacity: 0, ease: Power1.easeIn }, 0)
        .to(footerWrap, 0.3, { opacity: 0, ease: Power1.easeIn }, 0)
        .to(transitionBox, 0.5, { y: 0, ease: Power1.easeIn }, 0);
        //.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut })

    if(!isMobile)
        timeline
            .to(navWrap, 0.2, { opacity: 0, ease: Power1.easeIn }, 0)


    timeline.play();
};


const enter = (node, delay, lightNav, enterStartCallback, enterEndCallback) => {
    // content
    const timeline = new Timeline({ paused: true });

    // nav
    let color = "#171717";
    if(lightNav)
        color = "#f0ece6";


    // transition box that moves in from the bottom to the top
    const transitionBox = node.querySelectorAll('.transition-box');

    // page
    const pageWrap = node.querySelectorAll('.page__wrap');
    const pageContent = node.querySelectorAll('.page__content');
    const projectDesc = node.querySelectorAll('.project__description');
    const footerWrap = node.querySelectorAll('.footer__wrap');

    const navWrap = node.querySelectorAll('.nav__wrap');
    const heroTitle = node.querySelectorAll('.hero-title');
    //const navItem = node.querySelectorAll('.nav-item');
    //const navItemUnderlined = node.querySelectorAll('.nav-item__underlined');
    //let navItemUnderlinedBefore = CSSRulePlugin.getRule('.nav-item__underlined::before');


    const fadeInDuration = 0.5;
    const fadeInDelay = 0.5;
    timeline
        // fade out everything for the first second:
            // This is necessary because enter end exit fire at the same time, which they shouldn't.
            // This approach is a workaround for that problem, using a delay.
        .set(node, {  opacity: 0 })
        // set the nav color

        // Fade in the rest of the content after the delay.
        // move out the transitionBox after the delay.
        .set(node, {  delay: fadeInDelay, opacity: 1, ease: Power1.easeIn })

        //NAVIGATION set initial opacity back to 0
        .set(navWrap, {  opacity: 0, ease: Power1.easeIn })
        .set(pageContent, {  opacity: 0, ease: Power1.easeIn })
        .set(projectDesc, {  opacity: 0, ease: Power1.easeIn })
        .set(heroTitle, {  opacity: 0, ease: Power1.easeIn })
        .set(footerWrap, {  opacity: 0, ease: Power1.easeIn })
        /*

        .set(navItem,{ color: color, ease: Power1.easeInOut }, "nav")
        .set(navItemUnderlined,{ color: color, ease: Power1.easeInOut }, "nav")
        .set(navItemUnderlinedBefore, { backgroundColor: color, ease: Power1.easeInOut }, "nav")
         */
        .set(transitionBox, {  y: 0, opacity: 1, ease: Power1.easeIn }, "fadeIn")
        .to(transitionBox, fadeInDuration, { y: "-175vh", ease: Power1.easeOut})
        .call(enterStartCallback)
        .set(transitionBox, { opacity: 0, y: "-300vh" })
        .to(navWrap, 0.2, { delay: fadeInDuration -0.1, opacity: 1, ease: Power1.easeIn }, "fadeIn")
        .to(heroTitle, 0.2, { delay: fadeInDuration  -0.05, opacity: 1, ease: Power1.easeIn }, "fadeIn")
        .to(footerWrap, 0.4, { delay: fadeInDuration , opacity: 1, ease: Power1.easeIn }, "fadeIn")
        .to(pageContent, 0.35, {delay: 0.3, opacity: 1, ease: Power1.easeIn }, "fadeIn")
        .to(projectDesc, 0.7, {delay: fadeInDuration + 0.1, opacity: 1, ease: Power1.easeIn }, "fadeIn")
        //.set(transitionBox, {  y: "-300vh", opacity: 0, ease: Power1.easeIn })
        .call(enterEndCallback)
        //.to(navItemUnderlinedBefore, { backgroundColor: color, ease: Power1.easeInOut }, "nav")

    /*
        // content
        .from(node, 0.15, { display: 'none', autoAlpha: 0, delay, ease: Power1.easeIn },0)
        .from(content, 0.15, { autoAlpha: 0, ease: Power1.easeInOut }, 0)
        .from(contentInner, 0.15, { autoAlpha: 0, delay: 0.15, ease: Power1.easeIn }, 0)

        // nav alpha fade
        .from(navWrap, 0.15, { autoAlpha: 0, ease: Power1.easeIn }, 0);
        // transition Box

     */

    return timeline;
};

/*

const getHomeTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    const texts = node.querySelectorAll('h1 > div');

    timeline
        .from(node, 0, { display: 'none', autoAlpha: 0, delay })
        .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

    return timeline;
};


// Nav transitions
export const enterNav = (pathname, node, appears) => {
    const delay = appears ? 0 : 0.5;
    let timeline;

    if (pathname === '/')
        timeline = getTimelineNavToLight(node, delay);
    else
        timeline = getTimelineNavToDark(node, delay);

    window
        .loadPromise
        .then(() => requestAnimationFrame(() => timeline.play()))
};

export const exitNav = (node) => {
    const timeline = new Timeline({ paused: true });

    timeline.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut });
    timeline.play();
};


const getTimelineNavToLight = (node) => {
    const timeline = new Timeline({ paused: true });

    const navItem = node.querySelectorAll('.nav__item');
    const navItemUnderlined = node.querySelectorAll('.nav__item--underlined');
    let navItemUnderlinedBefore = CSSRulePlugin.getRule('.nav__item--underlined::before');
    console.log("enterNavLight -- node:before: ", navItemUnderlinedBefore);

    timeline
        .to(navItem, 0.375, { color: "white", delay: 0, ease: Power1.easeInOut })
        .to(navItemUnderlined, 0.375, { color: "white", delay: 0, ease: Power1.easeInOut })
        .to(navItemUnderlinedBefore, 0.375, { backgroundColor: "white", delay: 0, ease: Power1.easeInOut });

    return timeline;
};

const getTimelineNavToDark = (node) => {
    const timeline = new Timeline({ paused: true });

    const navItem = node.querySelectorAll('.nav__item');
    const navItemUnderlined = node.querySelectorAll('.nav__item--underlined');
    let navItemUnderlinedBefore = CSSRulePlugin.getRule('.nav__item--underlined::before');
    console.log("enterNavDark -- node: ", navItemUnderlinedBefore);

    timeline
        .to(navItem, 0.375, { color: "#171717", delay: 0, ease: Power1.easeInOut })
        .to(navItemUnderlined, 0.375, { color: "#171717", delay: 0, ease: Power1.easeInOut })
        .to(navItemUnderlinedBefore, 0.375, { backgroundColor: "#171717", delay: 0, ease: Power1.easeInOut });

    return timeline;
};


 */