export const Constants = {
    SET_MOBILE: 'SET_MOBILE',
    SET_PROJECT: 'SET_PROJECT',
    NEXT_PROJECT: 'NEXT_PROJECT',
    PREV_PROJECT: 'PREV_PROJECT',
    NEXT_PROJECT_CLEAR: 'NEXT_PROJECT_CLEAR',

    PROJECT_ABOUND: "PROJECT_ABOUND",
    PROJECT_SCALED: "PROJECT_SCALED",
    PROJECT_TEAMTABLE: "PROJECT_TEAMTABLE",
    PROJECT_MLS: "PROJECT_MLS",

    SCROLL_DOWN: "SCROLL_DOWN",
    SCROLL_RESET: "SCROLL_RESET",
};