import React, {Component} from 'react';
import styled from 'styled-components'
import {Container, media } from 'styled-bootstrap-grid';
import PropTypes from "prop-types";

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import "../../css/alice-carousel.css";
import {connect} from "react-redux";
import {FaExpand} from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';
import VideoCover from 'react-video-cover';
import ProgressiveImg from "../atoms/ProgressiveImg";
import {getThumbnailUrl} from "../../utils/thumbnailLoader";


const Wrapper = styled.div`
  padding-bottom: ${(props) => props.compensateDotPadding ? "calc(var(--pad, 1px) - 31px)" : "var(--pad)"};
`;

const WrapperInner = styled.div`
  width: 100%;
  height: 100%;
  
`;

const SlideWrap = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  
  ${media.lg`
    padding-left: calc(var(--pad, 1px)/2);
     padding-right: calc(var(--pad, 1px)/2);
  `}
`;



const Image = styled.div`
  position: relative;;
    width: 100%;
    
    background-image: url(${(props)=>props.imgUrl}); 
    background-position: center center;
    background-size: 100% auto;
    
    height: 0;
    padding-bottom: ${(props) => ((props.height/props.width) * 100) + "%"}; /* for a 4:3 aspect ratio */ /* aspect ratio. Divide height through width to get the percentage */
`;

const LightBoxIconWrap = styled.div`
  position: absolute;
  top: 3px;
  right: 28px;
  
  ${media.lg`
    top: 14px;
    right: 72px;
  `}
`;

const LightBoxIcon = styled(FaExpand)`
  z-index: 5;
  position: relative;
  cursor: zoom-in;
  color: rgba(0,0,0,0.3);
  mix-blend-mode: hard-light;
  
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  transition-property: transform, color;
  
  &:hover {
    color: white;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  
`;

const VideoCoverS = styled(VideoCover)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ProgressiveImageWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: ${(props) => ((props.height/props.width) * 100) + "%"};
    overflow: hidden;
    background-image: ${props => (props.overlayRGBA)};  
`;


class Carousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lightBoxOpened: false,
            lightBoxSlide: 0,
        };
    }

    toggleLightBox(index){
        if(!this.state.lightBoxOpened){
            this.Carousel.slideTo(this.props.lightBoxSlide);
        }
        this.setState({lightBoxOpened: !this.state.lightBoxOpened, lightBoxSlide: index })
    }



    render() {

        const handleOnDragStart = e => e.preventDefault();

        let contentWidth = (window.innerWidth - 1200);
        const stagePadding =
            (contentWidth / 2)
            + (contentWidth / 6) ;

        return (
            <Wrapper compensateDotPadding={this.props.compensateDotPadding}>
                <WrapperInner>

                    <AliceCarousel
                        mouseDragEnabled
                        buttonsDisabled={true}
                        autoHeight={true}
                        infinite={true}
                        ref={(el) => (this.Carousel = el)}
                        dotsDisabled={false
                            //!this.props.device.isMobile // if we are on desktop, disable the dots
                        }
                        stagePadding={ this.props.device.isMobile ?
                            { //mobile
                                paddingLeft: 0,     // in pixels
                                paddingRight: 0
                            }
                        :
                            { //desktop
                                paddingLeft: stagePadding,     // 150 in pixels
                                paddingRight: stagePadding
                            }
                        }

                        responsive={  {
                            0: {
                                items: 1
                            },
                            1200: {
                                items: 1
                            }
                        }}
                    >
                        {this.props.slides.map((slide, index) =>

                                <SlideWrap key={"slide-" + slide.url} onDragStart={handleOnDragStart}>

                                    {slide.isVideo ?
                                        <VideoCoverS videoOptions={{
                                            src: slide.url,
                                            ref: videoRef => { this.videoRef = videoRef;},
                                            onClick: () => {
                                                if (this.videoRef && this.videoRef.paused) {
                                                    this.videoRef.play();
                                                } else if (this.videoRef) {
                                                    this.videoRef.pause();
                                                }
                                            },
                                        }}/>
                                        :
                                        <div>
                                        <LightBoxIconWrap>
                                            <LightBoxIcon onClick={() => this.toggleLightBox(index)}/>
                                        </LightBoxIconWrap>


                                                <ProgressiveImg style={{position: 'relative !important', bottom: '0', height: slide.height, width: slide.width, maxWidth: '100%', overlay: 'hidden', paddingTop: (((slide.height/slide.width) * 100) + "% !important")}}
                                                                image={process.env.PUBLIC_URL + slide.url}
                                                                preview={process.env.PUBLIC_URL + getThumbnailUrl(slide.url)}
                                                                maxHeight={slide.height}

                                                />



                                        </div>
                                    }
                                </SlideWrap>
                        )}

                    </AliceCarousel>


                    <FsLightbox
                        toggler={ this.state.lightBoxOpened }
                        slide={ this.state.lightBoxSlide + 1}
                        sources={ this.props.slides.map((slide) => (slide.url)) }
                    />
                </WrapperInner>
            </Wrapper>
        )
    }
}

/*
<ImageWrap width={GetResponsiveSizes(slide.height,slide.width).width} height={GetResponsiveSizes(slide.height,slide.width).height}>
                                    <Image imgUrl={process.env.PUBLIC_URL + slide.url}>
                                    </Image >
                                </ImageWrap>
 */

/**** HOW TO USE:

1. define setting objects for each carousel element:

        let slide_1 = {
            width:"100",
            height:"200",
            url:'/images/abound-hero.jpg',
            isVideo: false,
        };

        let slide_2 = {
            width:"250",
            height:"200",
            url:'/images/abound-hero.jpg',
            isVideo: false,
        };

        let slide_3 = {
            width:"500",
            height:"200",
            url:'/images/abound-hero.jpg',
            isVideo: false,
        };

2. create array and pass it via props to this component:

        <Carousel slides={[slide_1, slide_2, slide_3]}/>
*****/

Carousel.propTypes = {
    slides: PropTypes.array.isRequired,
    compensateDotPadding: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    device: state.device
});

export default Carousel = connect(mapStateToProps)(Carousel);