import {Constants} from '../constants';
import AboundProject from '../components/views/projects/AboundProject.js';
import ScaledProject from '../components/views/projects/ScaledProject.js';
import MLSProject from '../components/views/projects/MLSProject.js';
import TeamTableProject from '../components/views/projects/TeamTableProject.js';
import FlipFallProject from '../components/views/projects/FlipFallProject.js';


const initialState = {
  // all of our project are stored in here. This data is used for the routing in the Projects component.

  projects: [
    {
      id: Constants.PROJECT_MLS,
      path: '/projects/machine-learning-sandbox',
      component: MLSProject,
      lightNav: true,
    },
    {
      id: Constants.PROJECT_SCALED,
      path: '/projects/scaled-vr',
      component: ScaledProject,
      lightNav: true,
    },
    {
      id: Constants.PROJECT_ABOUND,
      path: '/projects/abound',
      component: AboundProject,
      lightNav: false,
    },

      /*
    {
      id: Constants.PROJECT_FLIPFALL,
      path: '/projects/flipfall',
      component: FlipFallProject,
      lightNav: true,
    },*/

    {
      id: Constants.PROJECT_TEAMTABLE,
      path: '/projects/teamtable',
      component: TeamTableProject,
      lightNav: true,
    },


  ],
  currentIndex: 0,
  currentProject: {
    id: Constants.PROJECT_MLS,
    path: '/projects/machine-learning-sandbox',
    component: MLSProject,
    lightNav: true,
  },
  next: null,
};


export function project(state = initialState, action = {}) {

  let currentIndex = state.currentIndex;
  //const {project, deletedProjects, memberships} = state;
  //console.log("project, currentIndex: ", currentIndex, "action.type: ", action.type, "findIndex: ", state.projects.findIndex(p => p.id === action.projectId));

  switch (action.type) {
    case Constants.SET_PROJECT:
      console.log("project reducer: SET_PROJECT");
      return {...state, currentIndex: (state.projects.findIndex(p => p.id === action.projectId)), currentProject: (state.projects.find(p => p.id === action.projectId)), next: null};

    // next button clicked
    case Constants.NEXT_PROJECT:
      console.log("next: ", currentIndex);

      if(currentIndex + 1 < state.projects.length)
        return {...state, next: state.projects[currentIndex  + 1]};
      else
        return {...state, next: state.projects[0]};

      // next button clicked
    case Constants.PREV_PROJECT:
      console.log("prev: ", currentIndex);

      if(currentIndex - 1 >= 0)
        return {...state, next: state.projects[currentIndex  - 1]};
      else
        return {...state, next: state.projects[state.projects.length-1]};


    case Constants.NEXT_PROJECT_CLEAR:
      return {...state, next: null};

    default:
      return state;

    /*
    case Constants.PROJECTS_GET_BY_ID_REQUEST:
      return {...state, isRequestingGetById: true};

    case Constants.PROJECTS_GET_BY_ID_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_GET_BY_ID_SUCCESS:
      project[project.findIndex(p => p.id === action.project_id)] = action.project;
      return {...state, project: project, isRequestingGetById: false};


    case Constants.PROJECTS_GET_ALL_REQUEST:
      return {...state, isRequestingGetAllProjects: true};

    case Constants.PROJECTS_GET_ALL_SUCCESS:
      // // const { project } = state;
      return {...state, project: action.project, isRequestingGetAllProjects: false};

    case Constants.PROJECTS_GET_ALL_FAILURE:
      return {...state, formErrors: action.errors};


    case Constants.PROJECTS_CREATE_REQUEST:
      return {...state, isRequestingCreateProject: true}

    case Constants.PROJECTS_CREATE_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_CREATE_SUCCESS:
      // const { project } = state;
      return {
        ...state,
        project: [action.data.project].concat(project),
        memberships: [action.data.membership].concat(memberships),
        isRequestingCreateProject: false
      };


    case Constants.PROJECTS_UPDATE_REQUEST:
      return {...state, isRequestingUpdateProject: true};

    case Constants.PROJECTS_UPDATE_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_UPDATE_SUCCESS:
      console.log(JSON.stringify(project))
      project[project.findIndex(p => p.id === action.project.id)] = action.project;
      return {...state, project: project, isRequestingUpdateProject: false};


    case Constants.PROJECTS_DELETE_REQUEST:
      return {...state, isRequestingDeleteProject: true};

    case Constants.PROJECTS_DELETE_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_DELETE_SUCCESS:
      deletedProjects.push(action.project_id);
      return {
        ...state, project: project.filter(p => p.id !== action.project_id),
        memberships: memberships.filter(m => m.project_id !== action.project_id),
        isRequestingDeleteProject: false,
        deletedProjects: deletedProjects
      };


    case Constants.PROJECTS_ADD_MEMBER_REQUEST:
      return {...state, isRequestingAddMember: true};

    case Constants.PROJECTS_ADD_MEMBER_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_ADD_MEMBER_SUCCESS:
      return {...state, memberships: [action.membership].concat(memberships), isRequestingAddMember: false};


    case Constants.PROJECTS_REMOVE_MEMBER_REQUEST:
      return {...state, isRequestingRemoveMember: true};

    case Constants.PROJECTS_REMOVE_MEMBER_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_REMOVE_MEMBER_SUCCESS:
      return {
        ...state, memberships: memberships.filter(m => m.id !== action.membershipId),
        isRequestingRemoveMember: false
      };


    case Constants.PROJECTS_GET_MEMBERS_REQUEST:
      return {...state, isRequestingGetMembers: true};

    case Constants.PROJECTS_GET_MEMBERS_FAILURE:
      return {...state, formErrors: action.errors};

    case Constants.PROJECTS_GET_MEMBERS_SUCCESS:
      const membershipsNew = memberships.filter(m => m.project_id !== action.project_id).concat(action.memberships);
      return {...state, memberships: membershipsNew, isRequestingGetMembers: false};
*/
  }
}