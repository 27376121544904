import React, {Component} from 'react';
import styled from 'styled-components'
import VisibilitySensor from "react-visibility-sensor";

const VisibilityWrap = styled.div`

`;


class VisibilityFader extends Component {

    state = {
        imgViz: false
    };


    render(){
        return (
            <VisibilitySensor
                onChange={(isVisible) => {
                    if(isVisible)
                        this.setState({imgViz: isVisible})
                }}
                style={{
                    width: '100%', height: '100%',
                }}
                partialVisibility

            >
                <div className={"visibility-fader"}
                     style={{
                        opacity: this.state.imgViz ? 1 : 0,
                        transition: 'opacity 500ms linear'
                     }}
                >
                    {this.props.children}
                </div>
            </VisibilitySensor>
        );
    }
}


export default VisibilityFader;


/*
                <h5 style={{ color: "white", paddingBottom: "0"}}>
                    {this.props.title}
                </h5>
                <h5>
                    {this.props.children}
                </h5>
 */


