import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'; // used to be only router not browserrouter
//import { history } from './utils/history.js';
//import { connect } from 'react-redux'
//import "redux-thunk";
import { BaseCSS } from 'styled-bootstrap-grid';
import { Transition, TransitionGroup } from 'react-transition-group';
import {play, exit} from './timelines/page.timeline'
import Projects from "./components/views/Projects.js";
import About from "./components/views/About.js";
import './App.scss';
import {connect} from "react-redux";
import * as Actions from './actions';
import {bindActionCreators} from "redux";

//<BaseCSS/>

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            windowWidth: 0,
            transitionFinished: false,
        }
    }

    componentWillMount(){
        this.updateIsMobile();
        this.updateViewportHeight();
        this.updateGutters();
    }

    // Set a isMobile variable in the Redux store, which will be accessible by all components connected to the store
    updateIsMobile(){
        if(window.innerWidth >= 1200)
            this.props.actions.setIsMobile(false);
        else
            this.props.actions.setIsMobile(true);
    }

    // fix 100vh => https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // fix the mobile viewport height problem, which usually occurs on mobile devices, where browser navigation areas are not included in the 100vh, making a 100vh page scrollable.
    // here we set a global property containing the correct height value.
    updateViewportHeight(){
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // global vertical content paddings, used by all sorts of components.
    updateGutters(){
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let pad = 48;
        if(window.innerWidth >= 1200)
        {
            pad = 96;
        }
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--pad', `${pad}px`);
    }

    enterEndCallback(){
        console.log("!!!! exitStartCallback");
        this.setState({transitionFinished: false})
    }

    enterStartCallback(){
        console.log("!!!! enterStartCallback");
        this.setState({transitionFinished: true})
    }



  render(){
      // We listen to the resize event
      window.addEventListener('resize', () => {
          if(window.innerWidth !== this.state.windowWidth){
              console.log("resize");
              // We execute the same script as before
              this.updateIsMobile();
              this.updateViewportHeight();
              this.updateGutters();

              this.setState({windowWidth: window.innerWidth});
          }
      });

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <BaseCSS />

            <div className="app">
                    <Route render={({ location }) => {
                        const { pathname, key } = location;
                        const { isMobile } = this.props.device;

                        return (
                            <div>
                                <TransitionGroup component={null}>
                                    <Transition
                                        key={key}
                                        appear={true}
                                        onEnter={(node, appears) => {play(pathname, node, appears, this.enterStartCallback.bind(this), this.enterEndCallback.bind(this)); console.log("onEnter");}}
                                        onExit={(node, appears) => exit(node, appears, isMobile)}
                                        timeout={{enter: 1000, exit: 550}}
                                    >
                                            <Switch location={location}>
                                                <Route path="/projects" render={(props) =>
                                                    <Projects {...props} transitionFinished={this.state.transitionFinished}/>
                                                } />
                                                <Route path="/about" component={About} />
                                                <Route path="/" component={Projects}/>
                                            </Switch>
                                    </Transition>
                                </TransitionGroup>
                            </div>
                        )
                    }}/>
            </div>
        </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
    device: state.device
});

// action dispatch to write to the store (we want to update the isMobile variable).
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default App = connect(mapStateToProps, mapDispatchToProps)(App);

/*
<TransitionGroup component={null}>
                            <Transition
                            key={key}
                            appear={true}
                            onEnter={(node, appears) => enterNav(pathname, node, appears)}
                            timeout={{enter: 750, exit: 150}}
                        >
                            <Switch location={location}>
                                <Route path="/project" render={(props) => <Nav {...props} />} />
                                <Route path="/" render={(props) => <Nav {...props} />} />
                            </Switch>
                        </Transition>
                            </TransitionGroup>
 */


/*
<Router history={history}>
    <BaseCSS />
    <div className="App">
        <Header/>

        <Switch>
            <Route path="/project" component={Projects} project={"abound"}/>
            <Route path="/" component={Hub} />
        </Switch>

        <Footer/>
    </div>
</Router>
 */



/*
return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
 */
