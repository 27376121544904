import React, {Component} from 'react';
import styled from 'styled-components'
import PropTypes from "prop-types";
import Wave from "./Wave";

//import Background from '../../../public/images/abound-hero.jpg';

/*images need to be in the public folder. this.props.imageURL needs to be '/banner.png' for an image directly in the public folder*/

const HeroWrapper = styled.div`
    width:100%;
    
`;

const HeroImage = styled.div`
    width: 100vw;
    //height: ${(props)=>props.height};
    
    background: url(${(props)=>props.imgUrl});  
    background-position: center;
    backgroundSize: cover;
    background-attachment: fixed;
    
    
    height: 0;
    padding-bottom: ${(props) => ((props.height/props.width) * 100) + "%"}; /* for a 4:3 aspect ratio */ /* aspect ratio. Divide height through width to get the percentage */

`;


const Image = styled.img`
    width: 100vw;
    height: ${(props)=> 'calc(100vw * ' + (props.height/props.width) + ")"};
    
    //background: url(${(props)=>props.imgUrl});  
    //background-position: center;
    //backgroundSize: cover;
    //background-attachment: fixed;
`;



class SeparatorImage extends Component {

    imgRef = React.createRef();

    constructor(props){
        super(props);

        this.state = {
            imgHeight: 0,
        }
    }

    render(){
        return (

                <Image src={process.env.PUBLIC_URL + this.props.imgUrl}
                       ref={this.imgRef}
                       height={this.props.height}
                       width={this.props.width}
                />

        );
    }
}

SeparatorImage.propTypes = {
    imgUrl: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

export default SeparatorImage;

/*
<HeroImage imgUrl={process.env.PUBLIC_URL + this.props.imgUrl}  height={this.props.height}>
                </HeroImage>
 */