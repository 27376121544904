import Nav from "./Nav";
import TransitionBox from "./TransitionBox";


import React, {Component} from 'react';
import styled from 'styled-components'
import CustomScroll from "react-custom-scroll";
import '../../css/react-custom-scroll.css';
import {connect} from "react-redux";
import * as Actions from "../../actions";
import {bindActionCreators} from "redux";


const ContentWrapper = styled.div`
    position: absolute;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: #171717;
    height:100%;
    width: 100vw;
    top: 0;
    left: 0;
`;

const ContentInner = styled.div`
background-color: #171717;
    position: relative;
    height: 100%;
    max-height: 100%;

`;

// default is opcaity: 0 needs to be set to 1 via animation timelines
class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasScrolledDown: false,
        }
    }

    render(){

        if(this.props.device.scrollDown){

            //console.log("PAGE SCROLL DOWN");
            //this.props.actions.scrollReset();
        }


        return (
            <div className={"page__wrap"}>
                <TransitionBox/>
                    <ContentWrapper className={"content__wrap"}>

                        <CustomScroll heightRelativeToParent="100%" ref={this.customScrollbarRef}
                                      scrollTo={
                                            this.props.device.scrollDown
                                          ?
                                            document.documentElement.style.getPropertyValue('--vh').replace(/px/,"") * 100
                                          :
                                              0
                                      }
                        >
                            <Nav/>
                            <ContentInner>
                                    {this.props.children}
                            </ContentInner>
                        </CustomScroll>
                    </ContentWrapper>


            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.device
});


// action dispatch to write to the store (we want to update the isMobile variable).
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default Page = connect(mapStateToProps, mapDispatchToProps)(Page);

//react-custom-scrollbars: (buggy)
// <Scrollbars className={"project-scrollbar"}
//                             autoHeight
//                             autoHeightMin={document.documentElement.style.getPropertyValue('--vh').replace(/px/,"") * 100}
//                 >