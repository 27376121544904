import React, {Component} from 'react';
import styled from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid';
import { Container, Row, Col } from 'styled-bootstrap-grid';


const Box = styled.div`
    width: 100vw;
    height: 150vh;
    background-color: #151515;
    position: absolute;
    z-index: 10000;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-400vh);
`;

class TransitionBox extends Component {
    render(){
        return (
            <Box className={"transition-box"}/>
        );
    }
}

export default TransitionBox;