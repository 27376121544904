import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { store } from './utils/Store';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider } from 'styled-components';
import {Provider} from "react-redux";
import {applyMiddleware, compose} from "redux";


// styled-components breakpoint setup
const gridTheme = {
    breakpoints: { // defaults below
        xl: 1360,
        lg: 1200,
        md: 992,
        sm: 768,
        xs: 575,
        // or you can use aliases
        // giant: 1200,
        // desktop: 992,
        // tablet: 768,
        // phone: 576,
        // smaller: 575,
    },
    row: {
        padding: 18, // default 15
    },
    col: {
        padding: 18 // default 15
    },
    container: {
        padding: 18, // default 15
        maxWidth: { // defaults below
            xl: 1160,
            lg: 960,
            md: 740,
            sm: 520,
            xs: 520,
            // or you can use aliases
            // giant: 1140,
            // desktop: 960,
            // tablet: 720,
            // phone: 540,
            // smaller: 540,
        },
    },
};


const styledTheme = {
    mainColor: 'white',
};




ReactDOM.render(
    <ThemeProvider
        theme={styledTheme}
    >
        <GridThemeProvider
            gridTheme={gridTheme}
        >
            <Provider store={store}>
                <App />
            </Provider>
        </GridThemeProvider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
