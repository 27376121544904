import {Component} from "react";
import React from "react";
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { media } from 'styled-bootstrap-grid';

import ProjectHero from '../organisms/ProjectHero.js';
import HeroImage from '../molecules/SeparatorImage.js';
import Wave from "../molecules/Wave";
import Content from "../organisms/Content";

import '../../images/icons.css';
import InfoTextBlock from "../_old/InfoTextBlock_old";
import Nav from "../organisms/Nav";
import Page from "../organisms/Page";
import HeadShot from '../../images/fwolf_avatar_500x500.jpg';
import Footer from "../organisms/Footer";


const AvatarImage = styled.img`
  width:60%;
  margin-left: 20%;
  margin-top: 0px;
  //border: solid 10px transparent;
  //border-radius: 250px;
  //box-shadow: 0 0 0pt 3pt white;
  
   /* Smooth outline with box-shadow: */
        //box-shadow: 0 0 3pt 2pt red;
    /* Hard "outline" with box-shadow: */
        //box-shadow: 0 0 0 2pt red;
  
  ${media.lg`
    width: 100%;
    margin-left: 0;
  `}
`;

const AvatarImageWrap = styled.div`
  padding-bottom: 40px;
  
  ${media.lg`
    margin-top: 2%;
    padding: 12%;
  `}
`;



const ContainerStyled = styled(Container)`
  min-height: calc(100vh - 284px); 
  ${media.lg`
  `}
`;


const ColStyled = styled(Col)`
  padding-bottom: 0;
  align-self: center;
  ${media.lg`
  `}
`;

const RowStyled = styled(Row)`
padding-bottom: 0;
  ${media.lg`
  min-height: calc(100vh - 284px); 
  `}
`;

const ContentStyled = styled(Content)`
      padding-bottom: 32px !important;    
`;

const LinkUnderlined = styled.span`
  position: relative;
  cursor: pointer;
  vertical-align: unset !important;

&:before {
  background-color: #fff;
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 28px;
  bottom: 0;
  left: 0;
  /*background-image: linear-gradient(90deg, #d23c69, #ffc850);*/
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
  ${media.lg`
  margin-top: 0;
  
  &:before {
  top: 32px;
}
  `}
`;

class About extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render(){
        return (
            <Page>
                <Content padded={true}>
                    <div className="content--inner">
                        <ContainerStyled justifyContent={"center"} >
                            <RowStyled>
                                <ColStyled col={12} lg={8} >
                                    <h1>
                                        Game and Web Development
                                    </h1>
                                    <p>
                                        I am Florian, always making something, in love with code and designing experiences.
                                        When I was around twelve I started making mods, maps and plugins for various games.
                                        Studying Game Design at HTW Berlin seemed like the logical next step to me,
                                        which fully satisfied my need for exploration and taught me a lot about teamwork
                                        and creating games. In 2017 I began freelancing as a frontend developer for
                                        various clients. Today I am the co-founder of &nbsp;
                                        <LinkUnderlined className={"hvr-wobble-top"} onClick={() => {window.location = "https://studionachtwerk.de/"}}>
                                        Studio Nachtwerk
                                        </LinkUnderlined>, working on Demolition Dinos!

                                    </p>
                                </ColStyled>
                                <ColStyled col={12} lg={4}    order={"first"} lgOrder={"first"}>
                                    <AvatarImageWrap>
                                    <AvatarImage src={HeadShot} alt="Florian Wolf" />
                                    </AvatarImageWrap>
                                </ColStyled>
                            </RowStyled>
                        </ContainerStyled>
                    </div>
                </Content>
                <Footer/>
            </Page>
        );
    }
}
export default About;

/*
Since I bought my first computer at age 14 I was always working on some project that enabled me to freely create.
                                        Both coding and designing always resonated with me.
                                        I was happy when the apparent solution to a challenging problem was suddenly working.
                                        Different projects emerged, all around what I was currently interested in.
                                        There were Minecraft Mods and Plugins,
                                        as well as all sorts of Maps and Mods for various Source games.
                                        <br/>
                                        Later I discovered Unity3D and the Unreal Engine 4, which got me on the exciting journey of making games.

                                        In 2017 I began freelancing as a frontend developer for various clients.
                                        <br/>
                                        I am currently in my 4th semester of Game Design (BA) at HTW Berlin,
                                        always looking forward to new opportunities!
 */

/*Webdesign was something I liked to persue as well*/

/*
                                        As a game enthusiast with a creative drive I was never quite satisfied with just playing games, I wanted to modify, create and shape the experience.
                                        <br/>
                                        <br/>
                                         Over the past 8 years I’ve worked with many different tools and engines, which resulted in all sorts of mods, plugins, maps, games and websites.
                                        <br/>
                                        <br/>
                                         Throughout these years I have acquired knowledge in different fields of programming and design, which proved to be valuable assets in past and hopefully in shared future project!
 */

/*
<div>
                            <SubText>
                                <ReactSVG
                                          src={IconArrowDown}
                                          beforeInjection={svg => {
                                              svg.classList.add('icon__arrow--down');
                                              svg.setAttribute('style', 'margin-top: -2px;');
                                          }}
                                />
                                <h4 className="noSelect" style={{paddingLeft: '10px', fontSize: "1.5em", color:"#757575"}}>
                                    DISCOVER MY WORK
                                </h4>
                            </SubText>
                        </div>
 */