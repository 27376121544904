import React, {Component} from 'react';
import styled from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid';
import { Container, Row, Col } from 'styled-bootstrap-grid';

const Block = styled.div`
    width:100%;
    padding-bottom: 36px;
`;


class InfoTextBlock extends Component {
    render(){
        return (
            <Block>
                <h5 className={"light"}>
                    {this.props.title}
                </h5>
                <p style={{ color: "#757575", marginTop: "-8px"}}>
                    {this.props.children}
                </p>
            </Block>
        );
    }
}


export default InfoTextBlock;


/*
                <h5 style={{ color: "white", paddingBottom: "0"}}>
                    {this.props.title}
                </h5>
                <h5>
                    {this.props.children}
                </h5>
 */