import {Component} from "react";
import React from "react";
import styled from 'styled-components'
import PropTypes from "prop-types";


const StyledText = styled.p`
  text-align: ${props => props.centeredText ? 'center' : (props.blockText ? "justify" : "left")};
  color: ${props => props.darkText ? "#333333" : "white"};
  hyphens: auto;
`;

class Text extends Component {

    render(){
        return (
            <StyledText blockText={this.props.blockText} darkText={this.props.darkText} centeredText={this.props.centeredText}>{this.props.children}</StyledText>
        );
    }
}


Text.propTypes = {
    blockText: PropTypes.bool,
    darkText: PropTypes.bool,
    centeredText: PropTypes.bool,
};

export default Text;