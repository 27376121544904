import React, {Component} from 'react';

import Wave from '../molecules/Wave.js';
import styled from 'styled-components';
import {Container, media} from "styled-bootstrap-grid";
import PropTypes from "prop-types";
import ProjectSwitcher from "../molecules/ProjectSwitcher";
import ReactFitText from 'react-fittext';
import {connect} from "react-redux";
import ProgressiveImg from "../atoms/ProgressiveImg";
import {getThumbnailUrl} from "../../utils/thumbnailLoader";

const HeroWrapper = styled.div`
    position: relative;
    z-index: 0;
    width:100vw;
    height: 0;
    padding: 0; /* remove any pre-existing padding, just in case */
    padding-bottom: 100%; /* aspect ratio. Divide height through width to get the percentage */
  
  ${media.lg`
    padding-bottom: calc((var(--vh, 1vh) * 100) - var(--descH)); /* for a 4:3 aspect ratio */
  `}
`;


const HeroImageWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;


const HeroImage = styled.div`
    position: absolute;;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    background-image: ${props => (props.overlayOpacity && props.overlayRGB ? 'linear-gradient(to top,rgba(' + props.overlayRGB + ', ' + props.overlayOpacity + '), rgba(' + props.overlayRGB + ', ' + props.overlayOpacity + ')), url(' + props.imgUrlMobile+')' : 'url(' + props.imgUrlMobile+')')};  
    background-position: ${props => (props.pictureAlignBottom ? 'center bottom' : 'center center')};  
    background-size: 100% auto;

    /* // BLUR 
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      background-position: center bottom;
    background-size: 100% auto;
      -webkit-filter: blur(10px);
      filter: blur(10px);
    }
    
     */
  ${media.lg`
    height: calc(100% + var(--descH));
background-image: ${props => (props.overlayRGBA ? 'linear-gradient(to top,' + props.overlayRGBA +',' + props.overlayRGBA + ', url(' + props.imgUrl+')' : 'url(' + props.imgUrl+')')};  
  `}
`;

const ProgressiveImageWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: ${props => (props.overlayRGBA)};  
  

  ${media.lg`
  
    height: calc(100% + var(--descH));
  `}
`;


const Title = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    line-height: 100%;
    justify-content:center;
    align-content:center;
    flex-direction:column; /* column | row */
    text-align: center;
    color: ${props => (props.isLight ? 'white' : '#1b1b1b')};
    font-family: "Helvetica Now Display", sans-serif;
    font-weight: 300;
    font-size: 38px;
    
    text-shadow: ${props => (props.isLight ? '0px 0px 6px rgba(23,23,23,0.8)' : 'transparent')};
    
    
  ${media.md`

    font-size: 72px;
    font-weight: 300;
  `};
    
  ${media.lg`
    height: calc(100% + var(--descH));
    margin-top: -6px;
    font-size: 96px;
    font-weight: 100;
  `}
`;

const SubTitle = styled.div`

  color: ${props => (props.isLight ? 'white' : '#1b1b1b')};
  margin-top: ${props => (props.subTitleMargin ? props.subTitleMargin : "0px")};
  line-height: 30px;
  font-size: 16px;
  font-family: "Helvetica Now Micro", sans-serif;
  font-weight: bold;
  text-transform: none;
  ${media.lg`
   line-height: 40px;
    font-size: 20px;
  `}
`;





class ProjectHero extends Component {

    render() {

        const { isMobile } = this.props.device;

        /*

 <HeroImage imgUrl={process.env.PUBLIC_URL + this.props.imgUrl}
                               imgUrlMobile={process.env.PUBLIC_URL + this.props.imgUrlMobile}
                               isLight={this.props.isLight}
                               overlayOpacity={this.props.overlayOpacity}
                               overlayRGBA={this.props.overlayRGB}
                               pictureAlignBottom={this.props.pictureAlignBottom}>
                    </HeroImage>

         */

        return (
            <HeroWrapper className={"hero-wrap"}>

                <ProgressiveImageWrap>

                    <ProgressiveImg style={{position: "absolute", bottom: '0', height: 'auto', minWidth:'100%', maxWidth: '100%', transform: 'scale(1.03)'}}
                                    image={process.env.PUBLIC_URL + (isMobile ? this.props.imgUrlMobile : this.props.imgUrl)}
                                    preview={process.env.PUBLIC_URL + getThumbnailUrl(isMobile ? this.props.imgUrlMobile : this.props.imgUrl)}
                                    overlayRGBA={this.props.overlayRGBA}
                                    noVisibilityLoad
                    />
                </ProgressiveImageWrap>

                <HeroImageWrap>







                    {this.props.noTitle ?
                        null
                        :
                        <Title className={"hero-title"} isLight={this.props.isLight}>
                            {this.props.title}

                            <ProjectSwitcher isLight={this.props.isLight}/>

                            {this.props.noSubTitle ?
                                null
                                :
                                <SubTitle isLight={this.props.isLight} subTitleMargin={this.props.subTitleMargin}>{this.props.subTitle}</SubTitle>
                            }
                        </Title>
                    }

                </HeroImageWrap>
                {this.props.noWave ?
                    null
                    :
                    <Wave top={false} speed={5}/>
                }

            </HeroWrapper>
        );
    }
}

ProjectHero.propTypes = {

    imgUrl: PropTypes.string.isRequired,
    imgUrlMobile: PropTypes.string.isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleMargin: PropTypes.string,
    noTitle: PropTypes.bool,
    noSubTitle: PropTypes.bool,
    noWave: PropTypes.bool,
    noArrows: PropTypes.bool,
    isLight: PropTypes.bool,
    pictureAlignBottom: PropTypes.bool,
    overlayRGBA: PropTypes.string, //default #rgba(23,23,23, 0.8)
};

const mapStateToProps = (state) => ({
    device: state.device
});

export default ProjectHero = connect(mapStateToProps)(ProjectHero);