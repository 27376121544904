import React, {Component} from 'react';
import styled from 'styled-components';

import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import {media } from 'styled-bootstrap-grid';
import {Container, Row, Col} from "styled-bootstrap-grid";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


const FooterWrap = styled.div`
  transition: 0.2s all;
  width: 100%;
  min-height: 128px;
  background-color: ${(props) => props.isProject? "#171717" : "#101010"}; //151515
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconsWrap = styled.div`
position: relative;
width: 78px;
height: 30px;
right: 0;
`;

const IconWrap = styled.div`
position: absolute;
`;

const IconWrapGithubMobile = styled.div`
padding-right: 18px;
`;

const IconWrapGithub = styled(IconWrap)`
margin-left:45px;
`;


const LinkedInStyled = styled(FaLinkedin)`
  color: #666666;
  height: 30px;
  width: 30px;
  cursor:pointer;
    -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
    
  &:hover {
    color: #aaaaaa;
  }
`;


const GithubStyled = styled(FaGithub)`
  color: #666666;
  height: 30px;
  width: 30px;
  position: relative;
  cursor:pointer;
    -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  &:hover {
    color: #aaaaaa;
  }
`;

const ContactText = styled.div`
  color: #fff;
  font-size: 18px;
  font-family: "Futura Bold", sans-serif;
  float: left;
  margin-top: 17px;
  
  ${media.lg`
  float: right;
  font-size: 30px;
  margin-top: -5px;
  `}
`;


const ContactTextUnderlined = styled.span`
  position: relative;
  cursor: pointer;
  vertical-align: unset !important;

&:before {
  background-color: #fff;
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  top: 25px;
  bottom: 0;
  left: 0;
  /*background-image: linear-gradient(90deg, #d23c69, #ffc850);*/
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
  ${media.lg`
  float: right;
  font-size: 30px;
  margin-top: 0;
  
  &:before {
  top: 36px;
}
  `}
`;


const InfoWrap = styled.div`
  padding-right: 18px;
  color: #666666;
  line-height: 18px;
  font-family: "Helvetica Now Micro", sans-serif;
  font-size: 10px;
  width: 100%;
  text-align: center;
  
  ${media.lg`
  text-align: left;
  font-size: 12px;
  `}
`;

const PaddedColumn = styled(Col)`
  padding-bottom: 10px;
  padding-top: 10px;
      display: grid;
      justify-content: right;
`;

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state={
            isProject: false,
        }
    }

    componentDidMount() {
        let pathname = this.props.location.pathname;
        if (pathname.includes('/projects'))
            this.setState({isProject: true});
        else
            this.setState({isProject: false});
    }


    render(){
        return (
            <div >
                <FooterWrap isProject={this.props.location.pathname.includes("/projects")}>
                    <Container className={"footer__wrap"}>

                        {this.props.device.isMobile ?
                            <Row justifyContent={"center"}>
                                <PaddedColumn col={4}>
                                    <IconsWrap>
                                        <IconWrapGithubMobile className={"hvr-icon-grow"}>
                                            <LinkedInStyled className={"hvr-icon"} onClick={() => {window.open("https://www.linkedin.com/in/florian-wolf-a5b204173/",'_blank');}}/>
                                        </IconWrapGithubMobile>
                                        <div className={"hvr-icon-grow"}>
                                            <GithubStyled className={"hvr-icon"} onClick={() => {window.open("https://github.com/flo-wolf",'_blank')}}/>
                                        </div>
                                    </IconsWrap>
                                </PaddedColumn>

                                <PaddedColumn col={12} order={"last"} style={{   justifyContent: "center" }}>
                                    <InfoWrap>
                                        © 2019 - Designed and Developed by Florian Wolf.<br/>
                                        Built using react.js
                                    </InfoWrap>
                                </PaddedColumn>

                                <Col col={8}  >
                                    <ContactText>
                                        Say&nbsp;
                                        <ContactTextUnderlined className={"hvr-wobble-top"} onClick={() => {window.location = "mailto:hi@flowo.de"}}>
                                            hi@flowo.de
                                        </ContactTextUnderlined>
                                    </ContactText>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col col={12} lg={1} lgOffset={1}>
                                    <IconWrap className={"hvr-icon-grow"}>
                                        <LinkedInStyled className={"hvr-icon"} onClick={() => {window.open("https://www.linkedin.com/in/florian-wolf-a5b204173/",'_blank');}}/>
                                    </IconWrap>
                                    <IconWrapGithub className={"hvr-icon-grow"}>
                                        <GithubStyled className={"hvr-icon"} onClick={() => {window.open("https://github.com/flo-wolf",'_blank');}}/>
                                    </IconWrapGithub>
                                </Col>

                                <Col col={12} lg={5}>
                                    <InfoWrap>
                                        © 2019 - Designed and Developed by Florian Wolf.<br/>
                                        Built using react.js
                                    </InfoWrap>
                                </Col>

                                <Col col={12} lg={4}>
                                    <ContactText>
                                        Say&nbsp;<ContactTextUnderlined className={"hvr-wobble-top"} onClick={() => {window.location = "mailto:hi@flowo.de"}}>hi@flowo.de</ContactTextUnderlined>
                                    </ContactText>
                                </Col>
                            </Row>
                        }

                    </Container>
                </FooterWrap>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.device
});

export default Footer = connect(mapStateToProps)(withRouter(Footer));
/*
<Wave top={false} color={"#171717"} speed={12}/>
 */

/*
                  <Row>
                              <FaLinkedinStyled/>
                              <GithubStyled/>
                  </Row>
                  */

