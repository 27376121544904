import {Component} from "react";
import React from "react";
import Footer from '../organisms/Footer.js';
import '../../images/icons.css';
import {Transition, TransitionGroup} from "react-transition-group";
import {exit, enter} from "../../timelines/project.timeline";
import {Redirect, Route, Switch} from "react-router-dom";
import Page from "../organisms/Page";
import {connect} from "react-redux";
import Project from "./projects/Project";
import CustomScroll from "react-custom-scroll";


class Projects extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Page>
                <Route render={({location}) => {
                    return (
                                    <Switch location={location}>
                                        {this.props.project.projects.map(p =>
                                            <Route path={p.path}
                                                   key={p.id}
                                                   render={(props) =>

                                                       <Project {...props} transitionFinished={this.props.transitionFinished} id={p.id}>

                                                           <p.component/>
                                                           <Footer/>
                                                       </Project>
                                                   }
                                            />
                                        )}
                                        <Redirect to='/projects/machine-learning-sandbox'/>
                                    </Switch>
                    )
                }}/>

            </Page>
        );
    }
}

// get the project data from the redux store, so that we can map and render them..
const mapStateToProps = (state) => ({
    project: state.project,
    device: state.device
});

export default Projects = connect(mapStateToProps)(Projects);


/*
<TransitionGroup component={null}>
                                <Transition
                                    key={key}
                                    appear={false}
                                    onEnter={(node) => enter(pathname, node)}
                                    onExit={(node) => exit(node, isMobile)}
                                    timeout={{enter: 750, exit: 150}}
                                >
                                    <Switch location={location}>
                                        {this.props.project.projects.map(p =>
                                            <Route path={p.path}
                                                   key={p.id}
                                                   render={(props) =>
                                                       <Project {...props} id={p.id}>
                                                           <p.component/>
                                                       </Project>
                                                   }
                                            />
                                        )}
                                        <Redirect to='/projects/abound'/>
                                    </Switch>
                                </Transition>
                            </TransitionGroup>
 */

/*
<div className="view">
                <div className="content">


                    <Route render={({ location }) => {
                        const { pathname, key } = location;
                        return (
                            <div>
                                <TransitionGroup component={null}>
                                    <Transition
                                        key={key}
                                        appear={false}
                                        onEnter={(node) => enter(pathname, node)}
                                        onExit={(node) => exit(node)}
                                        timeout={{enter: 750, exit: 150}}
                                    >
                                        <Switch location={location}>
                                            <Route path="/project/abound" component={AboundProject} />
                                            <Route path="/project/scaled"  component={ScaledProject} />
                                        </Switch>
                                    </Transition>
                                </TransitionGroup>
                            </div>
                        )
                    }}/>

                    <Footer/>

                </div>
            </div>
 */

