import {Component} from "react";
import React from "react";

import Content from "../../organisms/Content";
import Carousel from '../../organisms/Carousel';
import '../../../images/icons.css';
import ProjectHero from "../../organisms/ProjectHero";
import TextBlock from "../../molecules/TextBlock";
import ProjectDescription from "../../organisms/ProjectDescription";
import DoubleTextBlock from "../../molecules/DoubleTextBlock";
import ProjectInformation from "../../molecules/ProjectInformation";
import ColoredBackground from "../../atoms/ColoredBackground";


class FlipFallProject extends Component {

    render() {
        let slide_0 = {
            width: "1200px",
            height: "640px",
            url: '/images/scaled-vr/scaled-vr_screenshot_03.jpg',
            isVideo: false,
        };

        let slide_1 = {
            width: "1200px",
            height: "640px",
            url: '/images/scaled-vr/scaled-vr_screenshot_02.jpg',
            isVideo: false,
        };

        let slide_2 = {
            width: "1200px",
            height: "640px",
            url: '/images/scaled-vr/scaled-vr_screenshot_01.jpg',
            isVideo: false,
        };

        let slide_3 = {
            width: "1200px",
            height: "640px",
            url: '/images/scaled-vr/scaled-vr_screenshot_04.jpg',
            isVideo: false,
        };

        let slide_4 = {
            width: "1200px",
            height: "640px",
            url: '/images/scaled-vr/scaled-vr_screenshot_05.jpg',
            isVideo: false,
        };

        let slide_5 = {
            width: "1200px",
            height: "640px",
            url: '/images/scaled-vr/scaled-vr_screenshot_06.jpg',
            isVideo: false,
        };

        // as well as the total energy output.s

        return (
            <div>
                <ProjectHero imgUrl={'/images/flipfall//flipfall_hero.jpg'}
                             imgUrlMobile={'/images/flipfall/flipfall_hero.jpg'}
                             noWave={true}
                             title={"Flip Fall"}
                             subTitle={"Mobile Highscore Game"}
                             isLight
                />

                <ProjectDescription shadow>

                </ProjectDescription>

                <Content padded>
                    <ProjectInformation
                        blocks={[
                            {
                                caption: "time",
                                text:
                                    <span>
                                        2016<br/>
                                        3 months
                                    </span>
                            },
                            {
                                caption: "context",
                                text:
                                    <span>
                                        solo project
                                    </span>
                            },
                            {
                                caption: "platform",
                                text:
                                    <span>
                                        Mobile Platformer
                                        Android

                                    </span>
                            },
                        ]}/>

                    <Carousel compensateDotPadding slides={[slide_0, slide_1, slide_2, slide_3, slide_4, slide_5]} />

                    <TextBlock
                        hasTopPadding={false}
                        caption={<span>gameplay</span>}
                        captionMobile={<span>gameplay</span>}>
                            Press and hold right to accelerate into the pressed direction. Take your finger of the screen to
                    </TextBlock>



                    <ColoredBackground color={"#1b1b1b"}>
                        <TextBlock
                            hasTopPadding={false}
                            caption={<span>implementation</span>}
                            captionMobile={<span>implementation</span>}
                            sideCaption={false}
                        >
                            The application was developed in Unity3D, using the SteamVR plugin and a HTC Vive.
                            All food extends from an <i>interactable</i> script.
                            During controller input the nearest interactable gets parented into the hands of the player.
                            Food also consists about information about itself, which is stored in an <i>emission struct</i>.
                            All emission structs of all items placed in a parented area above the scale object get accumulated.
                            The total emissions are then dispatched via UnityEvents.
                            Each data-visualization-attraction listens to that event and if the value has changed,
                            it will start interpolation Coroutines, fluently updating the data visualization attraction.

                        </TextBlock>

                        <Carousel
                            slides={[
                                {
                                    width:"1200px",
                                    height:"600",
                                    url:'/images/scaled-vr/scaled-vr_process_01.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1200px",
                                    height:"600",
                                    url:'/images/scaled-vr/scaled-vr_process_02.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1200px",
                                    height:"600",
                                    url:'/images/scaled-vr/scaled-vr_process_03.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1200px",
                                    height:"600",
                                    url:'/images/scaled-vr/scaled-vr_process_04.jpg',
                                    isVideo: false,
                                },
                            ]}
                        />

                    </ColoredBackground>


                    <ColoredBackground color={"white"}>
                        <DoubleTextBlock
                            darkText={true}
                            captionLeft={<span>motivation</span>}
                            textLeft={
                                <span>
                                    Climate change demands and deserves education. For that reason we wanted to create
                                    an experience that puts certain facts into a very neutral and unprejudiced
                                    perspective. All data visualized has been thoroughly researched and equals real life averages.
                                    After seeing our data in VR for the first time,
                                    we were quite surprised how different the scaling was compared to what we imagined.
                                    Before, I actually had next to no concept for the volumes that I was suddenly able to put into context.
                                    The project helped me gain some perspective on the consequences of my own food consumption.

                                </span>
                            }
                            captionRight={<span>learnings</span>}
                            textRight={
                                <span>
                                    Without actually wanting to make a game, the resulted turned out to be quite fun.
                                    The simple mechanics required some very natural interactions, such as picking things up and placing them.
                                     Players frequently tried to stack as many things as possible onto the scale or juggled with
                                    some eggs. I learned that it is possible to have fun while interacting with visualized data.
                                    Overall, this three weeks project helped me reinforce my VR
                                    development workflow in Unity3D and opened me up on to different approaches of designing an experience.
                                    It also showed me how the VR development pipeline still has lots of stepping stones,
                                    such as having to deal with overly complex input mapping, device compatibility and performance limitations.
                                </span>
                            }
                        />



                    </ColoredBackground>


                </Content>
            </div>
        );
    }
}

export default FlipFallProject;