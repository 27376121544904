import {Component} from "react";
import React from "react";
import PropTypes from "prop-types";
import * as Actions from "../../../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CustomScroll from "react-custom-scroll";

// project wrapper component, updating the redux store with the newest currentProject (this one!)

class Project extends Component {


    constructor(props) {
        super(props);

        this.state = {
            transitionFinished: false,
        };
    }

    componentWillMount() {
        this.props.actions.nextProjectClear();

        //this.timer = setTimeout(() => this.props.actions.setProject(this.props.id), 550)
    }

    //componentWillUnmount() {
        //clearTimeout(this.timer);
    //}

    updateProjectReducer(){
        console.log("Update project reducer")
        this.setState({transitionFinished: true});
        this.props.actions.setProject(this.props.id);
    }

    // the input trigger has changed
    componentDidUpdate(prevProps) {
        if((!this.state.transitionFinished && this.props.transitionFinished) && prevProps.transitionFinished !== this.props.transitionFinished)
            this.updateProjectReducer();
    }


    render(){
        return (
            <div style={{height: "100%"}}>

                {this.props.children}
            </div>
        );
    }
}


Project.propTypes = {
    id: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default Project = connect(null, mapDispatchToProps)(Project);