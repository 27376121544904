import React, {Component} from 'react';
import styled from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import PropTypes from "prop-types";

/*
const Wrapper = styled.div`
    min-height: calc(100vh - 128px); // 142px = footer height. make sure the footer doesnt float when content is smaller than the page
    padding-top: 52px;
    padding-bottom: 52px;
    width:100%;
    background-color: #1B1B1B;
    z-index: 5;
  
  ${media.lg`
    padding-top: 72px;
    padding-bottom: 64px;
  `}
`;


const WrapperNoGutters = styled.div`
    min-height: calc(100vh - 128px); // 142px = footer height. make sure the footer doesnt float when content is smaller than the page
    width:100%;
    background-color: #1B1B1B;
    z-index: 5;
    padding-bottom: 52px;
    padding-top: 52px;
  
  ${media.lg`
    padding-top: 64px;
    padding-bottom: 16px;
  `}
`;
*/

const Wrapper = styled.div`
    min-height: calc(100vh - 128px); // 128 = footer height. make sure the footer doesnt float when content is smaller than the page
    width:100%;
    background-color: #171717;
    z-index: 5;
  ${media.lg`
    padding-top: var(--pad, 1px);
  `}
`;

const WrapperPadded = styled.div`
    min-height: calc(100vh - 128px); // 128 = footer height. make sure the footer doesnt float when content is smaller than the page
    width:100%;
    background-color: #171717;
    z-index: 5;
    padding-top: var(--pad, 1px);
  
  ${media.lg`
    padding-top: var(--pad, 1px);
  `}
`;


class Content extends Component {
    render() {
        const {padded} = this.props;

        return (
            <div>
                {padded ?
                    <WrapperPadded>
                        <div className={"page__content"}>
                            {this.props.children}
                        </div>
                    </WrapperPadded>
                    :
                    <Wrapper>
                        <div className={"page__content"}>
                            {this.props.children}
                        </div>
                    </Wrapper>
                }
            </div>
        );
    }
}

Content.propTypes = {
    padded: PropTypes.bool,
};

export default Content;