import {Component} from "react";
import React from "react";
import * as Actions from "../../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import styled from "styled-components";
import {Redirect} from "react-router-dom";

import {FaAngleDown, FaAngleLeft} from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa';

import { media } from 'styled-bootstrap-grid';


const Wrapper = styled.div`
    position: absolute;
    display:inline;
    width:100%;
    z-index: 5000;
    padding-left: 15px;
    padding-right: 15px;
  ${media.lg`
    padding-left: var(--pad);
    padding-right: var(--pad);
  `}
`;

const ArrowLeft = styled(FaAngleLeft)`

  transition: opacity 0.2s;
  opacity: 1;
  width: 18px;
  height: 18px;
  cursor: pointer;
  ${media.lg`
    width: 35px;
    height: 35px;
  `}
  
  &:hover{
    opacity: 0.5;
  }
`;

const ArrowRight = styled(FaAngleRight)`

   transition: opacity 0.2s;
  opacity: 1;

  width: 18px;
  height: 18px;
  cursor: pointer;
  ${media.lg`
    width: 35px;
    height: 35px;
  `}
  
  &:hover{
    opacity: 0.5;
  }
`;

const ArrowDown = styled(FaAngleDown)`

   transition: opacity 0.2s;
  opacity: 1;

  width: 18px;
  height: 18px;
  cursor: pointer;
  ${media.lg`
    width: 35px;
    height: 35px;
  `}
  
  &:hover{
    opacity: 0.5;
  }
`;

const IconWrapLeft = styled.div`
    float:left;
    margin-top: -38px;
    height: 30px;
  ${media.lg`
margin-top: -72px;
  `}
`;

const IconWrapRight = styled.div`
    float:right;
    margin-top: -38px;
    height: 30px;
  ${media.lg`
   margin-top: -72px;
  `}
`;

const IconWrapDown = styled.div`
    margin-top: -38px;
    height: 30px;
  ${media.lg`
   margin-top: -72px;
  `}
`;

class ProjectSwitcher extends Component {

    // if there is a "next" variable set in the store, render a redirect. Otherwise render the buttons.
    render(){
        return (
            <Wrapper>
                {this.props.project.next ?
                    <Redirect to={this.props.project.next.path} />
                    :
                    null
                }

                <IconWrapLeft className={"hvr-icon-bob--left hvr-icon-pulse"}>
                    <ArrowLeft className={"hvr-icon"} onClick={() => {this.props.actions.prevProject()}}/>
                </IconWrapLeft>
                <IconWrapRight className={"hvr-icon-bob hvr-icon-pulse"}>
                    <ArrowRight className={"hvr-icon"} onClick={() => {this.props.actions.nextProject()}}/>
                </IconWrapRight>


            </Wrapper>
        );
    }
}

/*
          <IconWrapDown className={"hvr-icon-bob--down hvr-icon-pulse"}>
                    <ArrowDown className={"hvr-icon"} onClick={() => {this.props.actions.scrollDown()}}/>
                </IconWrapDown>
 */

const mapStateToProps = (state) => ({
    project: state.project,
    device: state.device,
});


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});

export default ProjectSwitcher = connect(mapStateToProps, mapDispatchToProps)(ProjectSwitcher);