import React, {Component} from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types'; // ES6

import { TimelineMax as Timeline, Power1, CSSRulePlugin, TweenMax} from 'gsap/all';
import { Linear} from 'gsap';
import {connect} from "react-redux";
import {media} from "styled-bootstrap-grid";


const WaveWrapTop = styled.div`
    position: absolute;
    width: 100%;
    height: 15px;
    margin-top: 0px;
  
  ${media.lg`
    height: 25px;
  `}
`;

const WaveWrapBot = styled.div`
    position: absolute;
    width: 100%;
    height: 10px;
    margin-top: -22px;
  
  ${media.lg`
    height: 25px;
    margin-top: -29px;
  `}
`;

const WaveG = styled.g`
    color: ${(props)=>props.color};
    box-sizing: inherit;
    
    animation: move ${(props)=>props.speed}s linear infinite ;
    -moz-animation: move ${(props)=>props.speed}s linear infinite; /* Firefox */
    -webkit-animation: move ${(props)=>props.speed}s linear infinite; /* Safari and Chrome */
    -o-animation: move ${(props)=>props.speed}s linear infinite; /* Opera */

    @keyframes move {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
`;



class Wave extends Component {

    constructor(props){
        super(props);
        // reference to the DOM node
        this.wave = null;
        // reference to the animation
        this.myTimeline = null;
    }

    /*
    componentDidMount(){
        // use the node ref to create the animation


        this.myTween = TweenMax.to(
            this.wave,
            1,
            {
                repeat: -1,
                rotation: 360,
                ease: Linear.none
            }
        );


    }

     */

    render(){
        const Wave = () => {
            if (this.props.top) {
                return <WaveWrapTop >
                    <svg
                        width = "100%"
                        height = "100%"
                        viewBox = "0 0 1440 25"
                        preserveAspectRatio = "none"
                    >
                        <WaveG data-svg-origin="0 0"
                               color={this.props.color ? this.props.color : "#171717"}
                               speed={this.props.speed ? this.props.speed : 6}
                               ref={g => this.wave = g}
                        >
                            <path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5">
                            </path>
                        </WaveG>
                    </svg>
                </WaveWrapTop>
            }
            else {
                return <WaveWrapBot>
                    <svg
                        width = "100%"
                        height = "100%"
                        viewBox = "0 0 1440 25"
                        preserveAspectRatio = "none"
                    >
                        <WaveG data-svg-origin="0 0"
                               color={this.props.color ? this.props.color : "#171717"}
                               speed={this.props.speed ? this.props.speed : 6}
                        >
                            <path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 25 H 0 V 0">
                            </path>
                        </WaveG>
                    </svg>
                </WaveWrapBot>
            }
        };

        return (
            <Wave/>
        );
    }
}

Wave.propTypes = {
    top: PropTypes.bool.isRequired,
    color: PropTypes.string,
    speed: PropTypes.number,
};

export default Wave = connect()(Wave);

/*
<div className="WaveCover_waveCover___ITZhM" >
                    <svg
                        width = "1440px"
                        height = "25px"
                        viewBox = "0 0 1440 25"
                        preserveAspectRatio = "none"
                    >
                        <g data-svg-origin = "0 0"
                            transform = "matrix(1,0,0,1,-2150,0)"
                        >
                            <path
                                fill = "currentColor"
                                d = "M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5" >
                            </path>
                        </g>
                    </svg>
                </div>
 */


/*
const WaveTop = styled(WaveBase)`
  display: inline-block;
  background: pink;
  width: 200px;
  height: 200px;
  transition: transform 300ms ease-in-out;

  &:hover {
    transform: translate(200px, 150px) rotate(20deg)
  }
`;
 */