import {Component} from "react";
import React from "react";
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid';
import PropTypes from "prop-types";
import { media } from 'styled-bootstrap-grid';
import {connect} from "react-redux";
import TextBlock from "../molecules/TextBlock";


const Wrapper = styled.div`
  padding-top: 64px;
  background-color: #171717;
  
  ${media.lg`
    background-color: transparent;
  `}
`;

const WrapperInner = styled.div`
width: 100%;
height: 100%;
`;


const TextBlockInner = styled.span`
  font-weight: 700;
  text-shadow: ${(props) => props.shadow ? '0px 0px 6px rgba(23,23,23,0.8)' : 'transparent'}
`;

class ProjectDescription extends Component {

    // when the component went through the browser render engine, get its size and set a global property variable,
    // which can be used by the ProjectHero to define its size.
    refCallback = element => {
        if (element) {
            this.elementRef = element;
            this.setDescriptionHeightProperty(element.getBoundingClientRect().height);
        }
    };

    componentDidUpdate() {
        if (this.doReportSize) {
            this.setDescriptionHeightProperty(this.elementRef.getBoundingClientRect().height);
            this.doReportSize = false;
        }
    }

    setDescriptionHeightProperty(height){
        // don't use 72 (default desktop --pad value) as a bottom padding, only use 64, like this top padding.
        // still keep the oroginal actual padding on the textblock though, which creates the correct default padding for the element below
        // ==>
        // if desktop, make the visible part of this element smaller when scrolled up completely
        if(!this.props.device.isMobile)
            height = (height - document.documentElement.style.getPropertyValue('--pad').replace(/px/,"")) + 96;

        console.log("Height", height, document.documentElement.style.getPropertyValue('--pad'));

        document.documentElement.style.setProperty('--descH', `${height}px`);
    }

    render(){

        return (
                <Wrapper ref={this.refCallback}>
                    <WrapperInner className={"project__description"}>
                        <TextBlock noCaption={true} blockText={true} darkText={this.props.device.isMobile ? false : this.props.darkText} centeredText>
                            <TextBlockInner shadow={this.props.shadow}>{this.props.children}</TextBlockInner>
                        </TextBlock>
                    </WrapperInner>
                </Wrapper>
        );
    }
}

ProjectDescription.propTypes = {
    darkText: PropTypes.bool,
    shadow: PropTypes.bool,

};


const mapStateToProps = (state) => ({
    device: state.device,
    project: state.project,
});

export default ProjectDescription = connect(mapStateToProps)(ProjectDescription);