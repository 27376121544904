import {Component} from "react";
import React from "react";

import Content from "../../organisms/Content";
import Carousel from '../../organisms/Carousel';
import '../../../images/icons.css';
import ProjectHero from "../../organisms/ProjectHero";
import TextBlock from "../../molecules/TextBlock";
import ProjectDescription from "../../organisms/ProjectDescription";
import DoubleTextBlock from "../../molecules/DoubleTextBlock";
import ProjectInformation from "../../molecules/ProjectInformation";
import ColoredBackground from "../../atoms/ColoredBackground";
import styled from "styled-components";
import { media } from 'styled-bootstrap-grid';
import VideoBox from "../../molecules/VideoBox";
import VisibilityFader from "../../atoms/VisibilityFader";


const Link = styled.u`
  cursor: pointer !important;
  margin-top: -5px;
`;

const DescriptionDisclaimer = styled.span`

display: block;
font-family: 'Helvetica Now Micro', sans-serif;
 color: #a1a1a1;
 
font-size: 12px;
line-height: 16px;
padding-top: 8px;
padding-bottom: 6px;

  ${media.lg`
  display: inherit;
  font-size: 14px;
    line-height: unset !important;
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

class TeamTableProject extends Component {

    render() {
        // as well as the total energy output.s

        return (
            <div>
                <ProjectHero imgUrl={'/images/teamtable/teamtable_hero_small-login.jpg'}
                             imgUrlMobile={'/images/teamtable/teamtable_hero_mobile.jpg'}
                             pictureAlignBottom
                             noWave={true}
                             title={"TeamTable"}
                             subTitle={"Projectmanagement Webapp"}
                             isLight
                             overlayRGBA={"rgba(23,23,23, 0.6"}
                />

                <ProjectDescription shadow>
                    TeamTable is a work-in-progress project management webapp, built with react.js and Ruby on Rails.
                    You can currently test it <Link className={'hvr-wobble-top'} onClick={() => {window.open("http://teamtable.herokuapp.com/login",'_blank');}}>here.</Link><br/>
                    <DescriptionDisclaimer> Development server might need to start after inactivity. Wait and refresh.</DescriptionDisclaimer>
                </ProjectDescription>

                <Content padded>
                    <VisibilityFader>
                        <ProjectInformation
                            blocks={[
                                {
                                    caption: "time",
                                    text:
                                        <span>
                                            2019<br/>
                                            4 months
                                        </span>
                                },
                                {
                                    caption: "tasks",
                                    text:
                                        <span>
                                            frontend development<br/>
                                            web design
                                        </span>
                                },
                                {
                                    caption: "context",
                                    text:
                                        <span>
                                            HTW Berlin<br/>
                                            3rd semester project<br/>
                                            web-development class<br/>
                                        </span>
                                },
                                {
                                    caption: "team",
                                    text:
                                        <span>
                                            Janis Schanbacher<br/>
                                            Florian Wolf
                                        </span>
                                },
                            ]}
                        />
                    </VisibilityFader>

                    <VisibilityFader>
                        <VideoBox url={'https://www.dl.dropboxusercontent.com/s/b6c2zvue9061acj/teamtable_showreel_beta.mp4'}
                                  playPauseBackground
                        />
                    </VisibilityFader>

                    <VisibilityFader>
                        <TextBlock
                            blockText={true}
                            hasTopPadding={false}
                            caption={<span>walkthrough</span>}
                            captionMobile={<span>walkthrough</span>}>
                            In TeamTable you can manage and organize all of the tasks that your project requires.
                            Invite anyone to join your project. Comment on tasks, assign tags, add assignees and create as many sub tasks as you wish.
                            This gives you a lot of flexibility, not forcing you to use one specific project management strategy.
                            Organize your projects in whatever way works best for you!
                        </TextBlock>
                    </VisibilityFader>

                    <VisibilityFader>
                        <Carousel
                            slides={[


                                {
                                    width:"1280",
                                    height:"720",
                                    url:'/images/teamtable/teamtable_screenshot_02.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1280",
                                    height:"720",
                                    url:'/images/teamtable/teamtable_screenshot_03.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1280",
                                    height:"720",
                                    url:'/images/teamtable/teamtable_screenshot_04.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1280",
                                    height:"720",
                                    url:'/images/teamtable/teamtable_screenshot_06.jpg',
                                    isVideo: false,
                                },

                                {
                                    width:"1280",
                                    height:"720",
                                    url:'/images/teamtable/teamtable_screenshot_01.jpg',
                                    isVideo: false,
                                },
                                {
                                    width:"1280",
                                    height:"720",
                                    url:'/images/teamtable/teamtable_screenshot_05.jpg',
                                    isVideo: false,
                                },

                            ]}
                        />
                    </VisibilityFader>



                    <ColoredBackground color={"#202020"}>
                        <VisibilityFader>
                            <DoubleTextBlock
                                blockText={true}
                                darkText={false}
                                captionLeft={<span>implementation</span>}
                                textLeft={
                                    <span>
                                        The backend is handled by Ruby on Rails. It controls all the REST API calls the react.js frontend is making.
                                All API responses are asynchronously fetched and then handled by a react-redux store,
                                which serves as the distributor of all received data. The data is then
                                conditionally being fed into the components of the virtual DOM,
                                which the react lifecycle dynamically updates, changing the rendered output.
                                    </span>
                                }
                                captionRight={<span>visual design</span>}
                                textRight={
                                    <span>
                                              During the ideation phase I frequently sketched out the
                                current ideas. Later I refined them into first mockups using
                                Adobe Illustrator, trying to create a clean and
                                easy to use interface, which has an appealing and calm feeling to it.
                                This process not only helped to communicate ideas and discover design problems,
                                it also served as a great basis for planning
                                the react-component hierarchy.
                                    </span>
                                }
                            />
                        </VisibilityFader>


                        <VisibilityFader>
                            <Carousel
                                slides={[
                                    {
                                        width:"1280",
                                        height:"720",
                                        url:'/images/teamtable/teamtable_mockup_02.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1280",
                                        height:"720",
                                        url:'/images/teamtable/teamtable_process_01.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1280",
                                        height:"720",
                                        url:'/images/teamtable/teamtable_process_03.jpg',
                                        isVideo: false,
                                    },
                                    {
                                        width:"1280",
                                        height:"720",
                                        url:'/images/teamtable/teamtable_mockup_01.jpg',
                                        isVideo: false,
                                    },
                                ]}
                            />
                        </VisibilityFader>

                    </ColoredBackground>


                    <ColoredBackground color={"#fcfcfc"}>
                        <VisibilityFader>
                            <DoubleTextBlock
                                blockText={true}
                                darkText={true}
                                captionLeft={<span>learnings</span>}
                                textLeft={
                                    <span>
                                        This has been my first react.js as well as my first Ruby on Rails project.
                                        Naturally, I learned a lot about working with the MVC pattern,
                                        javascript and css conventions, best practices, security concerns, databases and
                                        various react modules. Also, that having a virtual DOM is pretty awesome.

                                        Considering there has been no prior knowledge of working with backends, a lot of things
                                        needed to be understood at once for everything to make sense.
                                        After I got the hang on it, I really enjoyed abstractifying components, it feels very clean and
                                        accelerates the workflow, especially when the project grows a lot.
                                        Infact, this website was made using react!
                                    </span>
                                }
                                captionRight={<span>future</span>}
                                textRight={
                                    <span>
                                        TeamTable is currently in Alpha. Most basic features already work or are
                                        implemented in the backend and only need a frontend component to access the data.
                                        There is still a lot of functionality missing though.
                                        Lots of features are planned, including profile editing as well as more extensive card editing,
                                        better mobile support, visual customization, notifications and a chat system.
                                    </span>
                                }
                            />
                        </VisibilityFader>
                    </ColoredBackground>
                </Content>
            </div>
        );
    }
}

export default TeamTableProject;