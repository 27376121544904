import { createStore } from 'redux';
//import { applyMiddleware } from 'redux';
//import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/indexReducer';

//const loggerMiddleware = createLogger();


export const store = createStore(
    rootReducer,
    /*
    applyMiddleware(
        thunkMiddleware,
        //loggerMiddleware
    )
    */

);