import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {media} from "styled-bootstrap-grid";
import { slide as Menu } from 'react-burger-menu'
import {connect} from "react-redux";


const DesktopNavWrap = styled.div`
  height: 100px;
  width: 100vw;
  z-index: 1;
  display: flow-root;
  position: absolute;
  top: 0;
  right: 0;
`;

const MobileNavWrap = styled.div`

`;
/*
  height: 100vh;
height: calc(var(--vh, 1vh) * 100);
overflow: hidden;
width: 100vw;
position: absolute;
top: 0;
right: 0;
*/

const FlorianWolfTopLeft = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  display: flow-root;
  position: absolute;
  text-align: center;
  font-size: 0.6em;
  top: 0;
  left: 0;
  color: rgb(189, 195, 199);

  ${media.lg`
    padding-top: 36px;
    padding-left: 72px;
  `}
`;

const FlorianWolf = styled.div`
  display: flow-root;
  position: absolute;
  text-align: center;
  align-content: center;
  font-size: 0.6em;
  font-weight: 300;
  margin-left: 3px;

  bottom: 0;
  color: rgb(72,72,72);
  padding-bottom: 32px !important;

  ${media.lg`
    padding-top: 36px;
    padding-left: 72px;
    margin-left: 0px;
    top: 0 !important;
    left: 0!important;
  `}
`;

const DesktopNavLinkWrap = styled.div`
  padding-top: 36px;
  padding-right: 72px;
  float: right;
  display: flow-root;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
`;

const NavLinkStyled = styled(NavLink)`
  
  display: flow-root;
  text-align: center;
  position: relative;
  
  ${media.lg`
    margin-left: 60px;
    display: inline-flex;
    text-align: left;
  `}
`;


const routes = [
    { to: '/projects/scaled-vr', label: 'projects' },
    { to: '/about', label: 'about' }
];

const mobileMenuStylesLight = {
    bmBurgerButton: {
        position: 'absolute',
        width: '25px',
        height: '20px',
        right: '15px',
        top: '15px',
        outline: 'none',
        WebkitTransform: 'scale(0.9)',
        transform: 'scale(0.9)',
    },
    bmBurgerBars: {
        background: '#ffffff',
        outline: 'none'
    },
    bmBurgerBarsHover: {
        background: '#a90000',
        outline: 'none !important',
    },
    bmCrossButton: {
        width: '36px',
        height: '30px',
        right: '15px',
        top: '15px',
        outline: 'none',
    },
    bmCross: {
        background: '#bdc3c7',
        outline: 'none !important',
        marginTop: '-3px'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
    },
    bmMenu: {
        background: '#222222',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
        textAlign: 'center',
        width: '100%',
        paddingTop: '35vh',
    },
    bmMorphShape: {
        fill: '#171717'
    },
    bmItemList: {
        color: '#b8b7ad',

        textAlign:'center',
        display: 'inline-block',
    },
    bmItem: {
        paddingBottom: '64px',

        outline: 'none',
        alignContent: 'center',

    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
    }
};

const mobileMenuStylesDark = {
    ...mobileMenuStylesLight,
    bmBurgerBars: {
        background: '#171717',
        outline: 'none'
    },
};


class Nav extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLight: true,
            activeLink: '',
            menuOpen: false,
        };
    }


    // add resize event listener
    componentDidMount() {

        // update color based on location
        let pathname = this.props.location.pathname;
        if (pathname.includes('/projects'))
            this.setState({isLight: this.props.project.currentProject.lightNav});
        else
            this.setState({isLight: true});
    }

    // remove the event listener
    componentWillUnmount() {
        // in case this component gets unmounted, allow scrolling again
        document.body.setAttribute("style", "overflow-y: hidden  !important; overflow-x: hidden !important");
    }

    // fix page content scrollable while the menu isOpened
    componentDidUpdate(prevProps, prevState) {
        if (this.props.device.isMobile) {
            // If the hamburger menu is open, disable scrolling on the site
            if (prevState.menuOpen !== this.state.menuOpen) {
                if (this.state.menuOpen) {
                    // overflow:hidden disables the scrolling on a desktop browser
                    // position: fixed is additionally needed for mobile devices
                    document.body.setAttribute("style", "overflow: hidden !important;");
                } else {
                    document.body.setAttribute("style", "overflow: visible  !important;");
                }
            }
        }
    }


    render() {

        const pathname = this.props.location.pathname;

        const isLight = pathname.includes('/projects') ?
            this.props.project.projects[this.props.project.currentIndex].lightNav
            :
            true;

        //const {isLight} = this.props.project.projects[this.props.project.currentIndex].lightNav
        const {isMobile} = this.props.device;


        const links = routes.map(({to, label}) => {
                return <NavLinkStyled
                    className={isLight || isMobile ?
                        "nav-item nav-item__underlined hvr-wobble-top"
                        :
                        "nav-item--dark nav-item__underlined--dark hvr-wobble-top"
                    }
                    strict to={to}
                    key={to}
                    activeClassName={"nav-item__active"}
                    onClick={() => {
                        this.setState({activeLink: to});
                    }}
                >
                    {label}
                </NavLinkStyled>
            }
        );

        return <div className={"nav__wrap"}>
            {isMobile ?
                <MobileNavWrap id="outer-container">

                    <Menu right width={'100%'}
                          styles={isLight ? mobileMenuStylesLight : mobileMenuStylesDark}
                          disableAutoFocus
                          elastic
                          outerContainerId={"outer-container"}
                          pageWrapId={"content__wrap"}
                          onStateChange={(state) => this.setState({menuOpen: state.isOpen})}
                    >
                        {links}
                        <FlorianWolf className={"noSelect "}>florian wolf</FlorianWolf>
                    </Menu>
                </MobileNavWrap>
                :
                <DesktopNavWrap>
                    <FlorianWolf>
                        <h4 className={isLight ? "nav-item noSelect" : "nav-item--dark noSelect"}>
                            florian wolf
                        </h4>
                    </FlorianWolf>
                    <DesktopNavLinkWrap>
                        <nav>{links}</nav>
                    </DesktopNavLinkWrap>
                </DesktopNavWrap>
            }
        </div>;

    }
}

const mapStateToProps = (state) => ({
    device: state.device,
    project: state.project
});

export default Nav = connect(mapStateToProps)(withRouter(Nav));
